import React from "react";
import { ClientOnly } from "react-client-only";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { STOCK_STATES } from "../utils/store-context";
import { useStock } from "../hooks/useStock";
import { useMetafields } from "../hooks/useMetafields";

export const InStockIndicator = ({
  product,
  variantId,
  initStock,
  ...props
}) => {
  const { IN_STOCK, LIMITED_STOCK } = STOCK_STATES;
  const { storefrontId, metafields } = product;
  const meta = useMetafields(metafields);

  const { checking, stock } = useStock(
    storefrontId,
    variantId,
    initStock || IN_STOCK
  );

  return (
    <span {...props}>
      {checking ? (
        <>
          <span className="inline-block w-3 h-3 bg-gray-300"></span>
          <FontAwesomeIcon
            icon={faSpinner}
            className="inline text-sm mx-1"
            style={{ width: "1em" }}
            spin={true}
          />
          Checking Stock
        </>
      ) : (
        <ClientOnly>
          {stock === IN_STOCK || stock === LIMITED_STOCK ? (
            stock === LIMITED_STOCK ? (
              <>
                <span className="inline-block w-3 h-3 bg-yellow-500"></span>{" "}
                Limited Stock
              </>
            ) : (
              <>
                <span className="inline-block w-3 h-3 bg-green-500"></span>{" "}
                {meta.isUniqueItem ? "Available" : "In Stock"}
              </>
            )
          ) : (
            <>
              <span className="inline-block w-3 h-3 bg-red-500"></span>{" "}
              {meta.isUniqueItem ? "Sold" : "Out of Stock"}
            </>
          )}
        </ClientOnly>
      )}
    </span>
  );
};

export default InStockIndicator;
