import React from "react";
import { graphql } from "gatsby";

import Layout from "../../../components/layout";
import PageInfo from "../../../components/pageInfo";
import ProductListing from "../../../components/productListing";

const ProductType = ({ data: { products }, pageContext: { productType } }) => {
  const pageTitle = productType;

  return (
    <Layout>
      <PageInfo title={[pageTitle, "Products"].join(" - ")} />
      <h1 className="text-6xl text-center mb-10 font-new-tegomin">
        {pageTitle}
      </h1>
      <ProductListing products={products.nodes} />
    </Layout>
  );
};

export const query = graphql`
  query ($productType: String!) {
    products: allShopifyProduct(
      filter: { productType: { eq: $productType } }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        productType
        ...ProductCard
      }
    }
  }
`;

export default ProductType;
