import { useContext, useState, useCallback, useEffect } from "react";
import { determineStockState, StoreContext } from "../utils/store-context";

export const useStock = (productId, variantId, initStock) => {
  const { client } = useContext(StoreContext);

  const [stock, setStock] = useState(initStock);
  const [checking, setChecking] = useState(true);

  const checkAvailability = useCallback(
    (productId) => {
      setChecking(true);
      client.product.fetch(productId).then((fetchedProduct) => {
        const variants = fetchedProduct?.variants ?? [];
        setStock(determineStockState(variants, variantId, "addToCart"));
        setChecking(false);
      });
    },
    [variantId, client.product]
  );

  useEffect(() => {
    checkAvailability(productId);
  }, [variantId, checkAvailability, productId]);

  return { checking, stock };
};
