import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import classNames from "classnames";

import { formatPriceRange, getImageWithPlaceholder } from "../utils/utils";
// import { BuyNow } from "./buyNow";
import InStockIndicator from "./inStockIndicator";

const ProductCard = ({ product, hidePrice, className }) => {
  const {
    slug,
    featuredImage,
    title,
    priceRangeV2: { minVariantPrice, maxVariantPrice },
  } = product;

  return (
    <div
      className={classNames(
        "flex flex-col pb-4 border-b-3 border-studio-burnt-umber",
        className
      )}
    >
      <Link to={slug} className="flex flex-col flex-grow">
        {featuredImage?.image && (
          <GatsbyImage
            image={getImageWithPlaceholder(
              featuredImage.image,
              featuredImage.placeholder
            )}
            alt={featuredImage.altText || ""}
          />
        )}
        <span className="h2 text-white font-new-tegomin flex-grow">
          {title}
        </span>
      </Link>
      {!hidePrice && (
        <span className="text-2xl mb-2 text-white">
          {formatPriceRange(
            minVariantPrice.amount,
            maxVariantPrice.amount,
            minVariantPrice.currencyCode
          )}
        </span>
      )}
      <InStockIndicator product={product} />
    </div>
  );
};

export default ProductCard;

export const query = graphql`
  fragment ProductCard on ShopifyProduct {
    storefrontId
    title
    handle
    slug: gatsbyPath(
      filePath: "/products/{ShopifyProduct.productType}/{ShopifyProduct.handle}"
    )
    priceRangeV2 {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    featuredImage {
      # image: localFile {
      #   childImageSharp {
      #     gatsbyImageData(width: 300, aspectRatio: 1, placeholder: BLURRED)
      #   }
      # }
      image: gatsbyImageData(width: 300, aspectRatio: 1)
      placeholder: gatsbyImageData(width: 8)
      altText
    }
    metafields {
      key
      value
    }
  }
`;
