import React from "react";
import classNames from "classnames";

import ProductCard from "./productCard";

const ProductListing = ({
  products,
  hidePrices,
  className,
  cardClassName,
  ...props
}) => {
  return (
    <div className={classNames("product-listing gap-8", className)}>
      {products.map((product, index) => (
        <ProductCard
          key={index}
          product={product}
          hidePrice={hidePrices}
          className={cardClassName}
        />
      ))}
    </div>
  );
};

export default ProductListing;
